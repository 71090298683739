import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import './ScheduleExecutionAreaListPage.css';
import { PiClipboardTextLight } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { areAllTasksFilledInArea } from '../utils/taskUtils';
import { callCreateScheduleSummary } from '../functions';
import LoadingIndicator from '../components/LoadingIndicator';
import SearchBar from '../components/SearchBar';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

const ScheduleExecutionAreaListPage = ({ scheduleExecution, setScheduleExecution, handleBack, handleAreaSelect, handleScheduleExecutionComplete, handleScheduleExecutionSuspend, handleScheduleExecutionDiscard }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [timer, setTimer] = useState(0);
  const [rightButtonTitle, setRightButtonTitle] = useState('Start');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (scheduleExecution.schedule.areasToDo.length === 0) {
      setRightButtonTitle(t('general.done'));
    } else {
      setRightButtonTitle(t('general.stop'));
    }
  }, [scheduleExecution, setRightButtonTitle]);

  useEffect(() => {
    if (!scheduleExecution.startDate) {
      const newScheduleExecution = { ...scheduleExecution, startDate: new Date().toISOString() };
      setScheduleExecution(newScheduleExecution);
    } else {
      const startDate = new Date(scheduleExecution.startDate);
      const now = new Date();
      const elapsedSeconds = Math.floor((now - startDate) / 1000);
      setTimer(elapsedSeconds);
    }
  }, [scheduleExecution, setScheduleExecution]);

  useEffect(() => {
    const interval = setInterval(() => setTimer(prev => prev + 1), 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleDialogClose = async (option) => {
    setOpenDialog(false);
    switch (option) {
      case 'save':
        const finalNotes = window.prompt(t('scheduleExecution.areaList.finalNotesPrompt'), '');
        if (finalNotes === null) {
          return;
        }

        setIsLoading(true); // Show loading indicator

        try {
          const scheduleSummary = {
            id: "any_id",
            startDate: Math.floor(new Date(scheduleExecution.startDate).getTime() / 1000),
            elapsedTime: timer,
            property: scheduleExecution.property,
            locationChecked: scheduleExecution.locationEnabled || false,
            schedule: scheduleExecution.schedule,
            endDate: Math.floor(new Date().getTime() / 1000),
            peopleCount: scheduleExecution.associatesCount,
            associates: [],//scheduleExecution.associates,
            payed: false,
            paymentType: "cash",
            notes: finalNotes === "" ? null : finalNotes,
          }
          const createdScheduleSummary = await callCreateScheduleSummary(scheduleSummary);
          handleScheduleExecutionComplete(createdScheduleSummary);
          console.log("Creteaed schedule summary", createdScheduleSummary);
        } catch (error) {
          //TODO: JD proper error handling
          alert('Error saving progress:', error);
        } finally {
          setIsLoading(false); // Hide loading indicator
        }
        break;
      case 'suspend':
        handleScheduleExecutionSuspend();
        break;
      case 'discard':
        handleScheduleExecutionDiscard();
        break;
      case 'cancel':
        return;
      default:
        return;
    }
  };
  
  const dialog = (
    <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
      <DialogTitle>{t('scheduleExecution.areaList.stopSchedule.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('scheduleExecution.areaList.stopSchedule.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDialogClose('save')}>{t('general.save')}</Button>
        <Button onClick={() => handleDialogClose('suspend')}>{t('general.suspend')}</Button>
        <Button color='error' onClick={() => handleDialogClose('discard')}>{t('general.discard')}</Button>
        <Button onClick={() => handleDialogClose('cancel')}>{t('general.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );

  const toggleSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(i => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const finishSelection = () => {
    for (let item of selectedItems) {
      if (!areAllTasksFilledInArea(item)) {
        const userWantsToEnterData = window.confirm(
          t('scheduleExecution.areaList.missingInputsStatus.message')
        );
        if (userWantsToEnterData) {
          handleAreaSelect(item);
          return;
        } else {
          return;
        }
      }
    }


    const alreadyCompleted = scheduleExecution.schedule.areasCompleted || [];
    const doneItems = [...alreadyCompleted, ...selectedItems];
    const newScheduleExecution = { ...scheduleExecution };
    newScheduleExecution.schedule.areasToDo = newScheduleExecution.schedule.areasToDo.filter(item => !selectedItems.includes(item));
    newScheduleExecution.schedule.areasCompleted = doneItems;
    setScheduleExecution(newScheduleExecution);
    setSelectedItems([]);
  };

  const handleRightButtonPress = async () => {
    setOpenDialog(true);
  };

  const handleIconPress = (area) => {
    handleAreaSelect(area);
  };

  const filterItems = (items) => {
    return items.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  const filteredToDoItems = filterItems(scheduleExecution.schedule.areasToDo);
  const filteredCompletedItems = filterItems(scheduleExecution.schedule.areasCompleted || []);

  return (
    <div className="schedule-execution-area-list-page">
      {dialog}
      <NavBar 
        title={formatTime(timer)} 
        onBackClick={handleBack} 
        rightButtonTitle={rightButtonTitle}
        onRightButtonClick={handleRightButtonPress}/>
      <SearchBar
        placeholder={t('scheduleExecution.areaList.search')}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      <div className="section">
        <h2>{t('scheduleExecution.areaList.todo')}</h2>
        {filteredToDoItems.length > 0 || scheduleExecution.schedule.areasToDo.length === 0 ? (
          filteredToDoItems.map((item, index) => (
            <div key={index} className="item-row">
              <input
                type="checkbox"
                checked={selectedItems.includes(item)}
                onChange={() => toggleSelection(item)}
              />
              <span onClick={() => toggleSelection(item)}>{item.name}</span>
              <button onClick={() => handleIconPress(item)}>
                <PiClipboardTextLight size={20} />
              </button>
            </div>
          ))
        ) : (
          <p>{t("scheduleExecution.areaList.todoEmpty")}</p>
        )}
      </div>
      <div className="section">
        <h2>{t("scheduleExecution.areaList.completed")}</h2>
        {filteredCompletedItems.length > 0 || (scheduleExecution.schedule.areasCompleted?.length || 0)  === 0 ? (
          filteredCompletedItems.map((item, index) => (
            <div key={index} className="item-row">
              <span>{item.name}</span>
              <button onClick={() => handleIconPress(item)}>
                <PiClipboardTextLight size={20} />
              </button>
            </div>
          ))
        ) : (
          <p>{t("scheduleExecution.areaList.completedEmpty")}</p>
        )}
      </div>
      {selectedItems.length > 0 && (
        <button className="finish-button" onClick={finishSelection}>
          {t("scheduleExecution.areaList.completedButton.title")} ({selectedItems.length})
        </button>
      )}
      {isLoading && (
        <div className="loading-modal">
          <LoadingIndicator />
        </div>
      )}
    </div>
  )
}

export default ScheduleExecutionAreaListPage;