
// An offline manager is a helper class that manages the offline state of the application.
const localStorage = window.localStorage;

const OfflineManager = {
  saveUnfinishedScheduleExecution: (scheduleExecution) => {
    const unfinishedScheduleExecutions = OfflineManager.getUnfinishedScheduleExecutions();
    const newUnfinishedScheduleExecutions = [...unfinishedScheduleExecutions, scheduleExecution];
    localStorage.setItem('offlineScheduleExecutions', JSON.stringify(newUnfinishedScheduleExecutions));
  },

  getUnfinishedScheduleExecutions: () => {
    try {
      return JSON.parse(localStorage.getItem('offlineScheduleExecutions')) ?? [];
    } catch {
      return [];
    }
  },
  deleteUnfinishedScheduleExecution: (id) => {
    const unfinishedScheduleExecutions = OfflineManager.getUnfinishedScheduleExecutions();
    const newUnfinishedScheduleExecutions = unfinishedScheduleExecutions.filter((scheduleExecution) => scheduleExecution.schedule.id !== id);
    localStorage.setItem('offlineScheduleExecutions', JSON.stringify(newUnfinishedScheduleExecutions));
  },
}

export default OfflineManager;