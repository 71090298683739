import React, { createContext, useState, useEffect, useContext } from 'react';
import { callGetAssociates } from '../functions';
import { CompanyContext } from './CompanyContext'

export const AssociatesContext = createContext();

export const AssociatesProvider = ({ children }) => {
  const [associates, setAssociates] = useState([]);
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedCompany) {
        try {
          const assocaitestList = await callGetAssociates(selectedCompany.id);
          setAssociates(assocaitestList);
        } catch (error) {
          console.error('Error fetching departments:', error);
        }
      } else {
        setAssociates([]);
      }
    };

    fetchProperties();
  }, [selectedCompany]);

  return (
    <AssociatesContext.Provider value={{ associates }}>
      {children}
    </AssociatesContext.Provider>
  );
};