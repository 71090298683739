// src/screens/ScheduleExecutionScreen.js
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleExecutionStartPage from '../pages/ScheduleExecutionStartPage';
import ScheduleExecutionCheckInPage from '../pages/ScheduleExecutionCheckInPage'
import ScheduleExecutionAreaListPage from '../pages/ScheduleExecutionAreaListPage';
import ScheduleExecutionTaskListPage from '../pages/ScheduleExecutionTaskListPage';
import { logStartSchedule, logCancelSchedule, logFinishSchedule, logSuspendSchedule } from '../analytics';
import OfflineManager from '../utils/OfflineManager';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './ScheduleExecutionScreen.css'; // Import the CSS file for animations

const ScheduleExecutionScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [scheduleExecution, setScheduleExecution] = useState(location.state.scheduleExecution);
  const [currentPage, setCurrentPage] = useState(scheduleExecution?.page)
  const preventPopStateRef = useRef(false);
  const [isForward, setIsForward] = useState(true); // State to track navigation direction
  const [selectedArea, setSelectedArea] = useState(scheduleExecution?.selectedArea);

  useEffect(() => {
    if (!scheduleExecution) {
      navigate('/myDay');
    }
  }, [scheduleExecution, navigate]);

  useEffect(() => {
    if(scheduleExecution) {
      scheduleExecution.page = currentPage;
      scheduleExecution.selectedArea = selectedArea;
      localStorage.setItem('scheduleExecution', JSON.stringify(scheduleExecution));
    }
  }, [currentPage, scheduleExecution, selectedArea]);

  const handleBackFromStart = () => {
    localStorage.removeItem('scheduleExecution');
    setCurrentPage(null);
    setScheduleExecution(null);
  };

  const handleStartFromStart = () => {
    logStartSchedule(
      scheduleExecution.property.companyId,
      scheduleExecution.schedule.name,
      scheduleExecution.property.id,
      scheduleExecution.schedule.id
    );

    setIsForward(true);
    setCurrentPage('checkIn');
  };

  const handleBackFromCheckIn = () => {
    logCancelSchedule(
      scheduleExecution.property.companyId,
      scheduleExecution.schedule.name,
      scheduleExecution.property.id,
      scheduleExecution.schedule.id
    )
    setIsForward(false);
    setCurrentPage('start');
  };

  const handleContinueFromCheckIn = () => {
    setIsForward(true);
    setCurrentPage('areaList');
  };

  const handleBackFromAreaList = () => {
    setIsForward(false);
    setCurrentPage('checkIn');
  };

  const handleAreaFromAreaList = (area) => {
    setIsForward(true);
    setCurrentPage('taskList');
    setSelectedArea(area);
  }

  const handleBackFromTaskList = () => {
    setIsForward(false);
    setCurrentPage('areaList');
    setSelectedArea(null);
  };

  const handleScheduleExecutionComplete = (scheduleSummary) => {
    logFinishSchedule(
      scheduleExecution.property.companyId,
      scheduleExecution.schedule.name,
      scheduleExecution.property.id,
      scheduleExecution.schedule.id
    )

    setCurrentPage(null);
    setScheduleExecution(null);
    localStorage.removeItem('scheduleExecution');
    navigate(`/reports/${scheduleSummary.id}`, { state: { scheduleSummary } });
    
  };

  const handleScheduleExecutionSuspend = () => {
    logSuspendSchedule(
      scheduleExecution.property.companyId,
      scheduleExecution.schedule.name,
      scheduleExecution.property.id,
      scheduleExecution.schedule.id
    )

    OfflineManager.saveUnfinishedScheduleExecution(scheduleExecution);
    localStorage.removeItem('scheduleExecution');
    setCurrentPage(null);
    setScheduleExecution(null);
  };

  const handleScheduleExecutionDiscard = () => {
    logCancelSchedule(
      scheduleExecution.property.companyId,
      scheduleExecution.schedule.name,
      scheduleExecution.property.id,
      scheduleExecution.schedule.id
    );
    localStorage.removeItem('scheduleExecution');
    setCurrentPage(null);
    setScheduleExecution(null);
  }

  const handleBeforeUnload = useCallback((event) => {
    const confirmationMessage = 'Are you sure you want to leave this page?';
    event.returnValue = confirmationMessage; // For legacy browsers
    return confirmationMessage; // Standard behavior
  }, []);

  const handlePopState = useCallback((event) => {
    if (preventPopStateRef.current) {
      preventPopStateRef.current = false;
      return;
    }

    if (currentPage === 'checkIn') {
      setIsForward(false);
      setCurrentPage('start');
      preventPopStateRef.current = true;
      navigate(1); // Prevent navigation
      return;
    }

    if (currentPage === 'areaList') {
      setIsForward(false);
      setCurrentPage('checkIn');
      preventPopStateRef.current = true;
      navigate(1); // Prevent navigation
      return;
    }

    if (currentPage === 'taskList') {
      setIsForward(false);
      setCurrentPage('areaList');
      preventPopStateRef.current = true;
      navigate(1); // Prevent navigation
      setSelectedArea(null);
      return;
    }

    const userConfirmed = window.confirm('Are you sure you want to leave this page?');
    if (userConfirmed) {
      localStorage.removeItem('scheduleExecution');
      navigate('/myDay');
    } else {
      preventPopStateRef.current = true;
      navigate(1); // Prevent navigation
    }
  }, [navigate, currentPage]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleBeforeUnload, handlePopState]);
  
  return (
    <div className="schedule-execution-container">
      <TransitionGroup>
        <CSSTransition
          key={currentPage}
          timeout={300}
          classNames={isForward ? 'page-forward' : 'page-backward'}
        >
          <div>
            {
              currentPage === 'checkIn' && (
                <ScheduleExecutionCheckInPage
                  scheduleExecution={scheduleExecution}
                  setScheduleExecution={setScheduleExecution}
                  handleBack={handleBackFromCheckIn}
                  handleContinue={handleContinueFromCheckIn}
                />
              )
            }
            {
              currentPage === 'start' && (
                <ScheduleExecutionStartPage
                  scheduleExecution={scheduleExecution}
                  setScheduleExecution={setScheduleExecution}
                  handleStart={handleStartFromStart}
                  handleBack={handleBackFromStart}
                />
              )
            }
            {
              currentPage === 'areaList' && (
                <ScheduleExecutionAreaListPage
                  scheduleExecution={scheduleExecution}
                  setScheduleExecution={setScheduleExecution}
                  handleBack={handleBackFromAreaList}
                  handleAreaSelect={handleAreaFromAreaList}
                  handleScheduleExecutionComplete={handleScheduleExecutionComplete}
                  handleScheduleExecutionSuspend={handleScheduleExecutionSuspend}
                  handleScheduleExecutionDiscard={handleScheduleExecutionDiscard}
                />
              )
            }
            {
              currentPage === 'taskList' && (
                <ScheduleExecutionTaskListPage
                  scheduleExecution={scheduleExecution}
                  setScheduleExecution={setScheduleExecution}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                  handleBack={handleBackFromTaskList}
                />
              )
            }
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default ScheduleExecutionScreen;