import { callLogEvents } from './functions';
import { getGeoLocation } from './utils/geoLocationUtils';
let analyticsToSend = [];

const getLastKnownCoordinates = async () => {
  try {
    const location = await getGeoLocation();
    return {
      latitude: location.lat,
      longitude: location.lng
    }
  } catch {
    return null;
  }
}

const logEvent = async (event) => {
  analyticsToSend.push(event);
  try {
    await callLogEvents(analyticsToSend);
    analyticsToSend = [];
  }
  catch {
    console.error('Error logging event');
  }
}

export const logStartSchedule = async (companyId, name, propertyId, scheduleId) => {
  const event = {
    eventType: 'startSchedule',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId,
      scheduleId
    }
  }

  await logEvent(event);
}

export const logCancelSchedule = async (companyId, name, propertyId, scheduleId) => {
  const event = {
    eventType: 'cancelSchedule',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId,
      scheduleId
    }
  }

  await logEvent(event);
}

export const logFinishSchedule = async (companyId, name, propertyId, scheduleId) => {
  const event = {
    eventType: 'finishSchedule',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId,
      scheduleId
    }
  }

  await logEvent(event);
}

export const logResumeSchedule = async (companyId, name, propertyId, scheduleId) => {
  const event = {
    eventType: 'resumeSchedule',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId,
      scheduleId
    }
  }

  await logEvent(event);
}

export const logSuspendSchedule = async (companyId, name, propertyId, scheduleId) => {
  const event = {
    eventType: 'suspendSchedule',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId,
      scheduleId
    }
  }

  await logEvent(event);
}



export const logIssueReport = async (companyId, propertyId, name) => { 
  const event = {
    eventType: 'issueReported',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId
    }
  }

  await logEvent(event);
};

export const logIssueEdit = async (companyId, propertyId, name) => {
  const event = {
    eventType: 'issueEdited',
    companyId,
    timestamp: Date.now() / 1000,
    location: await getLastKnownCoordinates(),
    parameters: {
      name,
      propertyId
    }
  }

  await logEvent(event);
}